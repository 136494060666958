export default {
    // 测试
    listButton: '按钮',
    // 导航栏
    navLogOut: "退出登录",
    navLogin: "登录",
    // 镜像列表页面
    // pod服务页面
    // apiKeys页面
    apiKeys_describe: "不要与他人共享您的API密钥，也不要在浏览器或其他客户端代码中公开它。",
    apiKeys_secret_key: "密钥",
    apiKeys_creationNewKey: "创建新密钥",
    apiKeys_editKey: "编辑密钥",
    // 计费页面
    billing_recharge: '充值',
    billing_rechargeRecord: '充值记录',
    billing_consumptionFlow: '消费流水',

    // 公用
    Loading: '加载中...',
    Added: '新增',
    DeleteAffirm: '确定删除吗 ?',
    Serial: "序号",
    Desc: "描述",
    Name: "名称",
    State: "状态",
    Created_at: "创建时间",
    Start_at: "开始时间",
    End_at: "结束时间",
    Time: "时间",
    Operate: "操作",
    Start: "开始",
    User: "用户",
    Amount: "金额",
    Pay_time: "支付时间",
    OutTradeNo: "订单号",
    Remark: "备注",
    Category: "类别",
};