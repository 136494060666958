const server = {
    path: "/institute",
    name: "institute",
    component: () => import("../views/house.vue"),
    redirect: "/console/institute",
    meta: { title: "menuInstitute", },
    children: [
        {
            path: '/console/institute',
            name: 'consoleInstitute',
            component: () => import('../views/house/institute.vue'),
            meta: { title: "submenuInstitute" },
        },
        {
            path: '/console/lecturerDetails',
            name: 'consoleLecturerDetails',
            component: () => import('../views/house/lecturerDetails.vue'),
            meta: { title: "submenuLecturerDetails" },
        },
        {
            path: '/console/myInstitute',
            name: 'consoleMyInstitute',
            component: () => import('../views/house/myInstitute.vue'),
            meta: { title: "submenuMyInstitute" },
        },
        {
            path: '/console/instituteManage',
            name: 'consoleInstituteManage',
            component: () => import('../views/house/instituteManage.vue'),
            meta: { title: "submenuInstituteManage" },
        },
        {
            path: '/console/instituteNew',
            name: 'consoleInstituteNew',
            component: () => import('../views/house/instituteNew.vue'),
            meta: { title: "submenuInstituteNew" },
        },
        {
            path: '/console/instituteDetails',
            name: 'consoleInstituteDetails',
            component: () => import('../views/house/instituteDetails.vue'),
            meta: { title: "submenuInstituteDetails" },
        },
        {
            path: '/console/courseDetails',
            name: 'consoleCourseDetails',
            component: () => import('../views/house/courseDetails.vue'),
            meta: { title: "submenuCourseDetails" },
        },

    ]
};

export default server;
