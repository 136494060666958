

import { mapState, mapActions } from "pinia";
import { useHomeStore } from "../store";
import dayjs, { Dayjs } from "dayjs";
export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          dayjs,
          http_Docs: 'https://docs.chenyu.cn',
          http_File: this.$manageUrl.basepros == 'production' || this.$manageUrl.basepros == 'development' ? 'https://file.chenyu.cn/api/' : localStorage["baseUrl"] || this.$manageUrl.baseUrl,
        }
      },
      computed: {
        // 此处引入的变量，在各个页面都可以访问到
        ...mapState(useHomeStore, [
          "token",
          "user",
          'broadsideWidth',
          'modelsValueState',
          'systemctlEnableSshdVirtualId',
          'loginPop',
          'virtualId',
          'noticeTodayCliPopData',
          'homeMenuType',
          'recentlyPodShowDate',
          'clickTopShow',
          'ifLocalTime',
          'currentTime',
          'initPerformance',
        ]),
      },
      // mounted() {
      //   console.log('common.js');
      // },
      methods: {
        ...mapActions(useHomeStore, [
          'changeToken',
          'changeUser',
          'changeBroadsideWidthState',
          'changeModelsValueState',
          'changeSystemctlEnableSshdVirtualIdState',
          'changeLoginPopState',
          'changeVirtualIdState',
          'changeNoticeTodayCliPopDataState',
          'changeHomeMenuTypeState',
          'changeRecentlyPodShowDateState',
          'changeClickTopShowState',
          'changeIfLocalTimeState',
          'changeCurrentTimeState',
          'changeInitPerformanceState',
        ]),
        // 退出登录
        mixinLogOut() {
          this.$http
            .POST(this.$manageUrl.userLoginout, {})
            .then((res) => {
              if (res) {
                console.log('退出登录');
                this.changeToken(null)
                this.changeUser(null)

                this.mixinCheckLoginStatus()
              }
            })
            .catch((error) => {
              console.log(error);
              this.$message.error(error.msg || error.message || "ERROR");
            });
        },
        // 校验登录状态
        mixinCheckLoginStatus() {
          console.log(this.token);
          console.log(this.user);
          if (!this.token && !this.user) {
            this.$router.push("/");
          }
        },
        // 判断终端是否为触摸设备 
        isTouch() {
          if ("ontouchstart" in window) {
            return true
          } else {
            return false
          }
        },
        getInfo(type) {
          return new Promise((resolve, reject) => {
            this.$http
              .POST(this.$manageUrl.getInfo, {})
              .then((res) => {
                if (res) {
                  let data = res.result.user
                  if (data.avatar_url && type != 'noUpdataAvatar') {
                    data.avatar_url =
                      data.avatar_url + "?" + this.rondomPass(10);
                  }
                  this.changeUser(data);
                  resolve(true);
                }
              })
              .catch((error) => {
                // reject(error) // 抛出就得处理，抛出不处理就会报错 Uncaught (in promise) 
                console.log(error);
                this.$message.error(error.msg || error.message || "ERROR");
              });
          })
        },
        // 格式化时间
        timestampToTime(timestamp) {
          if (timestamp === 0 || timestamp == null) {
            return ''
          } else {
            var date = new Date(dayjs(timestamp))
            var Y = date.getFullYear() + '-'
            var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
            var H = date.getHours() + ':'
            var M2 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
            var S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
            return Y + M + D + H + M2 + S
          }
        },
        // 随机数生成
        rondomPass(number) {
          var arr = new Array();
          var arr1 = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");
          var nonceStr = "";
          for (var i = 0; i < number; i++) {
            var n = Math.floor(Math.random() * 10);
            arr[i] = arr1[n];
            nonceStr += arr1[n];
          }
          return parseInt(nonceStr);
        },
        // ios的滚动穿透问题
        penetrationRoll(e) {
          // 开启禁止
          if (e == 'kai') {
            document.body.style.overflow = "hidden";
            document.getElementById("app").style.overflow = "hidden";
            // 关闭禁止
          } else if (e == 'guan') {
            document.body.style.overflow = "visible";
            document.getElementById("app").style.overflow = "visible";

          }
        },
        // 复制
        mixinCopy(str, param) {
          let string = str;
          console.log(string);

          var textarea = document.createElement("textarea");
          textarea.style.position = "fixed";
          textarea.style.opacity = 0;
          textarea.value = string || "";
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);

          this.$message.success(param?.hint || "复制成功");
        },
        // 跳转
        mixinOpenNewWindow(url) {
          window.open(url, "_blank");
        },
        // 补位两位数
        mixinCoveringPosition(value, digit = 2) {
          return parseInt(value).toString().padStart(digit, "0");
        },
        // 字节转义
        fileSizeResolution(value) {
          // 默认传入单位字节B
          let str =
            value
              ? value / 1024 / 1024 / 1024 > 1
                ? (value / 1024 / 1024 / 1024).toFixed(2) + "GB"
                : value / 1024 / 1024 > 1
                  ? (value / 1024 / 1024).toFixed(2) + "MB"
                  : value / 1024 > 1
                    ? (value / 1024).toFixed(2) + "KB"
                    : value + "B"
              : value + "B"
          return str
        },
        getCurrentTime() {
          this.changeIfLocalTimeState(true) // 请求之前先重置
          let A = `${performance.now()}`
          this.$http
            .GET(this.$manageUrl.siteTime + `?${this.rondomPass(10)}`, {})
            .then((res) => {
              if (res) {
                console.log(dayjs(res.result.cur_unix_milli).format('YYYY-MM-DD HH:mm:ss'));
                let B = `${performance.now()}`
                // 接口响应时间超过2秒就作废
                if (Math.abs(B - A) > 2000) {
                  this.getCurrentTime()
                  return
                }
                // 本地时间和服务器时间误差超过30秒，就取服务器时间作为当前时间
                if (Math.abs(dayjs() - res.result.cur_unix_milli) > (1000 * 30)) {
                  this.changeIfLocalTimeState(false)
                  this.changeCurrentTimeState(res.result.cur_unix_milli)
                  this.changeInitPerformanceState(`${performance.now()}`)
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
        currentDayJs() {
          if (this.ifLocalTime) {
            return dayjs()
          } else {
            let time = dayjs(this.currentTime).add(
              `${performance.now()}` - this.initPerformance,
              "millisecond"
            );
            return time
          }
        },
      }
    })
  }
}
