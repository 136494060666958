import axios from 'axios'
import qs from 'qs'
import manageUrl from '@/http/manage'
import { useHomeStore } from "../store";
import data from "../router"
const router = data.router

// console.log(manageUrl.baseUrl)
axios.defaults.timeout = 120000;  // 响应时间ms
axios.defaults.headers.post['Content-Type'] = 'application/json';  // 配置请求头
axios.defaults.baseURL = localStorage["baseUrl"] || manageUrl.baseUrl;  // 配置接口地址
// axios.defaults.baseURL = 'http://10.0.51.81:5002/api';  // 本机联调接口
// axios.defaults.withCredentials = true

// 请求拦截器-----------------------------------------------------
axios.interceptors.request.use((config) => {
  if (config.url == 'files' || config.url == 'train_files') {
    if (manageUrl.basepros == "production" || manageUrl.basepros == "development") {
      config.baseURL = 'https://file.chenyu.cn/api/'
    }
  }
  // 在发送请求之前做某件事

  // 某个接口配置某个超时时间
  if (
    config.url == "train_files" &&
    config.uploadFile
  ) {
    config.timeout = 1200000000 * 1000;
  }

  // 某个接口配置某个请求头
  if (
    config.url == "/v1/goods/upload_custom_product" ||
    config.uploadFile
  ) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }

  // 如果有token就加token
  const store = useHomeStore()
  let token = store.token
  config.headers['Authorization'] = token && token != 'null' ? token : ''

  return config;
}, (error) => {
  console.log('fail:错误的传参');
  return Promise.reject(error);
});

// 响应拦截器-----------------------------------------------------
axios.interceptors.response.use((res) => {
  // 对响应数据做些事
  // token失效
  if (res.data.code == 2) {
    const store = useHomeStore()
    // 退出登录
    store.changeToken(null)
    store.changeUser(null)

    router.push('/'); // 如果去redirect重定向页面，多个请求同时触发会乱掉
  }
  return res;
}, (error) => {
  return Promise.reject(error);
});

// (封装post请求)--------------------------------------------------
export function fetchPost(url, params, param2) {
  if (param2 && param2.isloading) {
    // console.log(param2.isloading)
  }
  return new Promise((resolve, reject) => {
    axios.post(url, params, {
      uploadFile: param2 && param2.uploadFile,
      responseType: param2 && param2.filesDownload ? 'blob' : ''
    },)
      .then((res) => {
        if (res.data.code == 0) {
          resolve(res.data);
        } else {
          if (param2 && param2.filesDownload) {
            resolve(res.data);
            return false
          }
          reject(res.data);
          // ***.error('网络错误')
        }
      }, err => {
        reject(err);
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// (封装get请求)---------------------------------------------------
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: param })
      .then(res => {
        if (res.data.code == 0) {
          resolve(res.data);
        } else {
          reject(res.data);
        }
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}