// 每个状态管理文件都要引入此方法
import { defineStore } from 'pinia'
import dayjs, { Dayjs } from "dayjs";

// 官方建议取名遵从 useXXXStore 形式
// 'index' 为当前store的唯一标识 类似ID 
// 取名建议与文件名称一致 便于记忆和管理
// pinia舍弃了冗长的mutations属性 
// 以下是pinia的一种写法 因与vuex相似 便于学习和记忆

// 值发生改变自动存入本地一份，然后保持继续沉默，
// 网页刷新的时候去localStorage取值补上刷掉的vueX
export const useHomeStore = defineStore('chenyu', {
    state: () => {
        return {
            num: 0,
            token: null,
            user: null,
            broadsideWidth: 250,
            modelsValueState: null,
            systemctlEnableSshdVirtualId: null,
            loginPop: false, // 路由跳转部分页面时发现未登录，则改变此值显示弹框，默认false
            virtualId: null,
            noticeTodayCliPopData: [],
            homeMenuType: 1,
            recentlyPodShowDate: '',
            clickTopShow: false,
            ifLocalTime: true, // 当前之间是否是本地时间
            currentTime: 0,
            initPerformance: 0,
        }
    },
    actions: {
        changeNum(e: any) {
            //这里可以使用this去拿到state里定义的变量
            this.num = e
        },
        changeToken(e: any) {
            this.token = e
        },
        changeUser(e: any) {
            this.user = e
        },
        changeBroadsideWidthState(e: any) {
            this.broadsideWidth = e
        },
        changeModelsValueState(e: any) {
            this.modelsValueState = e
        },
        changeSystemctlEnableSshdVirtualIdState(e: any) {
            this.systemctlEnableSshdVirtualId = e
        },
        changeLoginPopState(e: any) {
            this.loginPop = e
        },
        changeVirtualIdState(e: any) {
            this.virtualId = e
        },
        changeNoticeTodayCliPopDataState(e: any) {
            this.noticeTodayCliPopData = e
        },
        changeHomeMenuTypeState(e: any) {
            this.homeMenuType = e
        },
        changeRecentlyPodShowDateState(e: any) {
            this.recentlyPodShowDate = e
        },
        changeClickTopShowState(e: any) {
            this.clickTopShow = e
        },
        changeIfLocalTimeState(e: any) {
            this.ifLocalTime = e
        },
        changeCurrentTimeState(e: any) {
            this.currentTime = e
        },
        changeInitPerformanceState(e: any) {
            this.initPerformance = e
        },
    },
    getters: {
        // 这里取名不可与state里的变量一致 所以取名getNum
        getNum: state => state.num
    }
}) 