export default {
    // 测试
    listButton: 'Button',
    // 导航栏
    navLogOut: "LogOut",
    navLogin: "Login",
    // 镜像列表页面
    // pod服务页面
    // apiKeys页面
    apiKeys_describe: "Do not share your APl key with others, or expose it in the browser or other client-side code.",
    apiKeys_secret_key: "Secret_key",
    apiKeys_creationNewKey: "Create a new key",
    apiKeys_editKey: "Edit a key",
    // 计费页面
    billing_recharge: 'recharge',
    billing_rechargeRecord: 'recharge record',
    billing_consumptionFlow: 'consumption flow',

    // 公用
    Loading: 'Loading...',
    Added: 'Added',
    DeleteAffirm: 'Are you sure to delete it ?',
    Serial: "Serial",
    Desc: "Desc",
    Name: "Name",
    State: "State",
    Created_at: "Created_at",
    Start_at: "Start_at",
    End_at: "End_at",
    Time: "Time",
    Operate: "Operate",
    Start: "Start",
    User: "User",
    Amount: "Amount",
    Pay_time: "Pay_time",
    OutTradeNo: "OutTradeNo",
    Remark: "Remark",
    Category: "Category",
};